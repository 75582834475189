import { createSlice, createAsyncThunk, current, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { getRandomLightColor } from "../components/random";
import { getRandomColor } from "../components/random";
import fetchWithRefresh from "./fetchWithRefresh";
import getConfig from "../app/config";

export const getViews = createAsyncThunk<any, {templateType: number  }>(
  "view/getViews",
  async (arg) => {
    const { templateType } = arg;
    return await fetchWithRefresh(getConfig().apiUrl + `view/list/${templateType}`);
  }
)

export const getView = createAsyncThunk<string, string>(
  "view/getView",
  async (id) => {
    return await fetchWithRefresh(getConfig().apiUrl + `view/${id}`);    
  }
)

export const addView = createAsyncThunk<any, any>(
  "vies/addView",
  async (view, thunkApi) => {    
    return await fetchWithRefresh(getConfig().apiUrl + "view", thunkApi, {
      method: 'POST',
      headers: {       
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(view),
    });
  }
)

export interface ViewsState {
    //groups: any[],
    view: any,
    boreholes: any[],
    allGroupsTheSame: boolean,
    showView: boolean,
    notSaved: boolean,
    viewsLoading: boolean,
    viewsLoaded: boolean,
    error: any,
    allViews: any[],
    userViews: any[],
    clientViews: any[],
    selectedViewId: any,
    selectedUserViewId: any,
    selectedClientViewId: any,
    nextViewId: any,
    nextViewLevel: any,
    canSaveView: boolean,
    canDeleteView: boolean,
    viewLoading: boolean
  }
  
  const initialState: ViewsState = {
    //groups: [],
    view: { groups: [] },
    boreholes: [],
    allGroupsTheSame: true,
    showView: false,
    notSaved: false,
    viewsLoading: false,
    viewsLoaded: false,
    error: null,
    allViews: [],
    userViews: [],
    clientViews: [],
    selectedViewId: null,
    selectedUserViewId: null,
    selectedClientViewId: null,
    nextViewId: null,
    nextViewLevel: null,
    canSaveView: true,
    canDeleteView: true,
    viewLoading: false
  }

  const sortViews = (state: ViewsState) => {    
    state.userViews.sort((a: any, b: any) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
    state.allViews.sort((a: any, b: any) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });    
  }

  const addTracksToGroup2 = (tracks: any[], group: any, groups: any[]) => {    
    tracks.forEach((track: any) => {    
      const boreholeGroup = groups.find((g: any) => g.boreholeId == track.boreholeId);
      const backgroundColor = boreholeGroup?.color;      
      if (track.trackType === 1) { //Image
        let myuuid = uuidv4();
        const imageTrackType: any = null;
        const track2: any = {
          id: myuuid,
          boreholeName: track.boreholeName, 
          boreholeId: track.boreholeId,
          trackName: track.trackName, 
          displayName: track.trackName + " Image",
          displayOrder: group.tracks.length + 2, 
          label: track.trackName, 
          imageType: track.imageType,
          trackType: 1,
          trackWidth: 150,
          legend: imageTrackType?.legendName, 
          legendRollover: imageTrackType?.legendRollover,
          color: backgroundColor
          };           
        group.tracks.push(track2);
      } else if (track.trackType === 3 || track.trackType === 4) { // Cumulative, Tadpole
        let myuuid = uuidv4();

        const curves: any = [];
        let curvedisplayOrder = 1;
        track.curves.forEach((curve: any) => {
          const color = getRandomColor();    
          let curveId = uuidv4();          
          curves.push({                                    
            id: curveId,
            displayOrder: curvedisplayOrder,
            fillColor: color + "80",
            fillSize: 5,
            fillStyle: "",
            lineColor: color,
            lineSize: 1,
            lineStyle: "line",
            majorIntervals: 6,
            manualMajorIntervals: false,
            manualMinorIntervals: false,
            manualScaleXMaximum: false,
            manualScaleXMinimum: false,
            minorIntervals: 1,
            pointColor: color,
            pointSize: 5,
            pointStyle: "circle",            
            showFill: track.trackType === 3,
            showLine: true,
            showPoints: track.trackType === 4,
            curveType: track.allowedCurveTypes?.length ? track.allowedCurveTypes[0] : 0,
            trackTypeId: track.trackId,                  
            xScaleMaximum: null,
            xScaleMinimum: null,
            color: backgroundColor,                  
            ...curve });
            curvedisplayOrder++;
        })

        const track2: any = {
          boreholeName: track.boreholeName, 
          boreholeId: track.boreholeId,
          trackName: track.trackName, 
          label: track.trackName, 
          trackId: track.trackId,
          id: myuuid,                                            
          displayOrder: group.tracks.length + 2, 
          new: true, 
          scaleType: 0,
          trackWidth: 150,
          showGridlines: true,
          showValueAxisAnnotation: true, //TODO: get this from somewhere
          curves,
          trackType: track.trackType,
          color: backgroundColor,
          units: track.units,
          allowedCurveTypes: track.allowedCurveTypes, 
        };
        group.tracks.push(track2);
      } else if (track.trackType === 0) { // Graph
        let curveId = uuidv4();
        let myuuid = uuidv4();
        const color = getRandomColor();        
        const track2: any = {
          boreholeName: track.boreholeName, 
          boreholeId: track.boreholeId,
          trackName: track.trackName, 
          label: track.trackName, 
          trackId: track.trackId,
          trackTypeId: track.trackTypeId,
          trackType: track.trackType ?? 0,
          id: myuuid,                                            
          displayOrder: group.tracks.length + 2, 
          new: true, 
          scaleType: 0,
          trackWidth: 150,
          showGridlines: true,
          showValueAxisAnnotation: true, //TODO: get this from somewhere
          color: backgroundColor,
          units: track.units,                
          displayName: track.trackName, 
          curves: [{ 
            displayName: track.trackName, 
            boreholeName: track.boreholeName, 
            boreholeId: track.boreholeId, 
            projectName: track.projectName,
            trackCategoryName: track.trackCategoryName, 
            units: track.units,
            allowedCurveTypes: track.allowedCurveTypes, 
            id: curveId,
            displayOrder: 1,
            fillColor: color + "80",
            fillSize: 5,
            fillStyle: "",
            lineColor: color,
            lineSize: 1,
            lineStyle: "line",
            majorIntervals: 6,
            manualMajorIntervals: false,
            manualMinorIntervals: false,
            manualScaleXMaximum: false,
            manualScaleXMinimum: false,
            minorIntervals: 1,
            pointColor: color,
            pointSize: 5,
            pointStyle: "circle",            
            showFill: true,            
            showLine: true,
            showPoints: false,
            curveType: track.allowedCurveTypes?.length ? track.allowedCurveTypes[0] : 0,
            trackTypeId: track.trackId,                  
            xScaleMaximum: null,
            xScaleMinimum: null,
            color: backgroundColor
          }]
          };            
        group.tracks.push(track2);            
    } else if (track.trackType === 5) { // Lithology              
        let myuuid = uuidv4();              
        const track2: any = {
          boreholeName: track.boreholeName, 
          boreholeId: track.boreholeId,
          trackName: track.trackName, 
          label: track.trackName, 
          trackId: track.trackId,
          trackTypeId: track.trackTypeId,
          trackType: track.trackType ?? 0,
          id: myuuid,                                            
          displayOrder: group.tracks.length + 2, 
          new: true, 
          scaleType: 0,
          trackWidth: 150,
          showGridlines: true,
          showValueAxisAnnotation: true, //TODO: get this from somewhere
          color: backgroundColor,
          units: track.units,                
          displayName: track.trackName              
          };            
        group.tracks.push(track2);
      } else if (track.trackType === 7) { // text
        let myuuid = uuidv4();              
        const track2: any =
         {                                 
          trackTypeId: track.trackTypeId,
          trackType: track.trackType,                                          
          collapsed: track.collapsed,
          boreholeName: track.boreholeName, 
          boreholeId: track.boreholeId,
          trackName: track.trackName, 
          label: track.trackName, 
          trackId: track.trackId,                
          id: myuuid,                                            
          displayOrder: group.tracks.length + 2, 
          new: true, 
          scaleType: 0,
          trackWidth: 150,
          showGridlines: true,
          showValueAxisAnnotation: true, //TODO: get this from somewhere
          color: backgroundColor,
          units: track.units,                
          displayName: track.trackName         
        };
        group.tracks.push(track2);
      } 
    });     
  };

const viewsSlice = createSlice({
    name: 'views',
    initialState,
    reducers: {   
      clearView: (state) => {
        state.showView = false;
        state.view = { groups: [] };
      },       
      addBorehole: (state, action: PayloadAction<any>) => {
        const { boreholeName, boreholeId, projectName, tracks } = action.payload;        
        if (!state.view.groups.find((g: any) => g.boreholeId == boreholeId)) {          
          const color = getRandomLightColor();     
          const tracksCopy = Array.from(tracks);
          tracksCopy.forEach((track: any) => {
            track.color = color;
            track.curves?.forEach((curve: any) => {
              curve.color = color;
            })
          });
          state.view.groups.push({ groupName: boreholeName, groupId: uuidv4(), projectName, boreholeId, boreholeName, tracks: tracksCopy, color });
        }
        
        state.boreholes.push({ name: boreholeName, id: boreholeId, project: projectName });
        state.notSaved = true;
        state.showView = true;
      },      
      addBoreholes: (state, action: PayloadAction<any>) => {
        const { boreholes } = action.payload;
        boreholes.forEach((borehole: any) => {        
          if (!state.view.groups.find((g: any) => g.boreholeId == borehole.id)) {          
            const color = getRandomLightColor();     
            const tracksCopy = Array.from(borehole.tracks);            
            tracksCopy.forEach((track: any) => {
              const trackId = uuidv4();
              track.id = trackId;
              track.trackId = trackId;
              track.color = color;
              track.curves?.forEach((curve: any) => {
                curve.id = uuidv4();
                curve.color = color;
              })
            });
            state.view.groups.push({ groupName: borehole.name, groupId: uuidv4(), projectName: borehole.project, boreholeId: borehole.id, boreholeName: borehole.name, tracks: tracksCopy, color });
          }
          
          state.boreholes.push({ name: borehole.name, id: borehole.id, project: borehole.project });
        });
        state.notSaved = true;
        state.showView = true;
      },      
      addTracksToGroups: (state, action: PayloadAction<any>) => {
        const { tracks } = action.payload;        
        state.view.groups.forEach((group: any) => {
          group.tracks = [];  
          tracks.forEach((track: any) => {
            group.tracks.push({...track,
              boreholeName: group.boreholeName, 
              color: group.color,
              projectName: group.projectName,
              boreholeId: group.boreholeId,
              trackName: track.label,
              trackId: track.id,
              trackType: track.trackType,
              curves: track.curves?.map((curve: any) =>{                 
                return { 
                ...curve, 
                color: group.color,
                displayName: curve.displayName, 
                boreholeName: group.boreholeName, 
                projectName: group.projectName,
                boreholeId: group.boreholeId,
                trackCategoryName: curve.trackCategoryName
              }})
            })  
          });          
        });

        state.notSaved = true;        
      },      
      addGraphTracksToGroups: (state, action: PayloadAction<any>) => {
        const { tracks } = action.payload;          
        state.view.groups.forEach((group: any) => {
          
          tracks.forEach((track: any) => {

            const color = getRandomColor();
            let myuuid = uuidv4();
            let curveId = uuidv4();
            if (!group.tracks) {
              group.tracks = [];
            }

            group.tracks.push({ 
              id: myuuid,                            
              trackType: 0,
              displayOrder: group.tracks.length + 2, 
              new: true, 
              scaleType: 0,
              trackWidth: 150,
              showGridlines: true,
              showValueAxisAnnotation: false,
              color: group.color,
              boreholeName: group.boreholeName,
              projectName: group.projectName,
              label: track.displayName,
              curves:[{
                id: curveId,
                boreholeId: group.boreholeId,
                boreholeName: group.boreholeName,
                projectName: group.projectName,
                color: group.color,
                displayName: track.displayName,
                displayOrder: 1,
                fillColor: color + "80",
                fillSize: 5,
                fillStyle: "",
                lineColor: color,
                lineSize: 1,
                lineStyle: "line",
                majorIntervals: 6,
                manualMajorIntervals: false,
                manualMinorIntervals: false,
                manualScaleXMaximum: false,
                manualScaleXMinimum: false,
                minorIntervals: 1,
                pointColor: color,
                pointSize: 5,
                pointStyle: "circle",            
                showFill: true,            
                showLine: true,
                showPoints: false,
                curveType: track.allowedCurveTypes[0],
                trackTypeId: track.trackTypeId,
                units: track.units,
                xScaleMaximum: null,
                xScaleMinimum: null
              }]
            });
          });          
        });

        state.notSaved = true;        
      },
      addImageTracksToGroups: (state, action: PayloadAction<any>) => {
        const { tracks } = action.payload;                        
        state.view.groups.forEach((group: any) => {          
          tracks.forEach((imageTrackType: any) => {
            let myuuid = uuidv4();
            group.tracks.push({ 
              id: myuuid,
              color: group.color,
              boreholeId: group.boreholeId,
              boreholeName: group.boreholeName,
              projectName: group.projectName,
              label: imageTrackType.displayName + " Image",
              trackType: 1,
              displayOrder: group.tracks.length + 2, 
              new: true,
              displayName: imageTrackType.displayName + " Image",
              imageType: imageTrackType.imageType,
              legend: imageTrackType?.legendName, 
              legendRollover: imageTrackType?.legendRollover,
              trackWidth: 150
            });
          });          
        });

        state.notSaved = true;        
      },     
      addCumulativeTracksToGroups: (state, action: PayloadAction<any>) => {
        const { tracks } = action.payload;          
        state.view.groups.forEach((group: any) => {
          const curves: any[] = [];
          let displayOrder = 1;
          tracks.forEach((trackType: any) => {

            const color = getRandomColor();
            let curveId = uuidv4();            
            curves.push({ 
              id: curveId,
              color: group.color,
              boreholeId: group.boreholeId,
              boreholeName: group.boreholeName,
              projectName: group.projectName,
              label: trackType.displayName,
              displayName: trackType.displayName,
              displayOrder: displayOrder++,
              fillColor: color + "80",
              fillSize: 5,
              fillStyle: "",
              lineColor: color,
              lineSize: 1,
              lineStyle: "line",
              majorIntervals: 6,
              manualMajorIntervals: false,
              manualMinorIntervals: false,
              manualScaleXMaximum: false,
              manualScaleXMinimum: false,
              minorIntervals: 1,
              pointColor: color,
              pointSize: 5,
              pointStyle: "circle",            
              showFill: true,            
              showLine: true,
              showPoints: false,
              trackTypeId: trackType.trackTypeId,
              units: "",
              xScaleMaximum: null,
              xScaleMinimum: null});
          });

          const trackLabel = tracks.map((curve: any) => curve.displayName).join(", ");
          let myuuid = uuidv4();
          group.tracks.push({ 
            color: group.color,
            id: myuuid,
            trackType: 3,
            displayOrder: group.tracks.length + 2, 
            boreholeId: group.boreholeId,
            boreholeName: group.boreholeName,
            projectName: group.projectName,
            label: trackLabel,
            new: true, 
            scaleType: 0,
            trackWidth: 150,
            showGridlines: true,
            showValueAxisAnnotation: group.showValueAxisAnnotation,
            curves:curves});
        });                  

        state.notSaved = true;        
      }, 
      addTadpoleTracksToGroups: (state, action: PayloadAction<any>) => {
        const { tracks } = action.payload;               
        state.view.groups.forEach((group: any) => {
          const curves: any[] = [];
          let displayOrder = 1;
          tracks.forEach((trackType: any) => {

            const color = getRandomColor();
            let curveId = uuidv4();
            curves.push({ 
              id: curveId,
              color: group.color,
              boreholeId: group.boreholeId,
              displayName: trackType.displayName,
              displayOrder: displayOrder++,
              fillColor: color + "80",
              fillSize: 5,
              fillStyle: "",
              lineColor: color,
              lineSize: 1,
              lineStyle: "line",
              majorIntervals: 6,
              manualMajorIntervals: false,
              manualMinorIntervals: false,
              manualScaleXMaximum: false,
              manualScaleXMinimum: false,
              minorIntervals: 1,
              pointColor: color,
              pointSize: 5,
              pointStyle: "circle",            
              showFill: false,
              showLine: true,
              showPoints: true,
              trackTypeId: trackType.trackTypeId,
              units: "",
              xScaleMaximum: null,
              xScaleMinimum: null});
          });

          const trackLabel = tracks.map((curve: any) => curve.displayName).join(", ");
          let myuuid = uuidv4();
          group.tracks.push({ 
            id: myuuid,
            trackType: 4,
            color: group.color,
            boreholeId: group.boreholeId,
            displayOrder: group.tracks.length + 2, 
            label: trackLabel,
            boreholeName: group.boreholeName,
            projectName: group.projectName,
            new: true, 
            scaleType: 0,
            trackWidth: 150,
            showGridlines: true,
            showValueAxisAnnotation: group.showValueAxisAnnotation,
            curves });
        });                  

        state.notSaved = true;        
      }, 
      addLithologyTracksToGroups: (state, action: PayloadAction<any>) => {
        const { tracks } = action.payload;
        state.view.groups.forEach((group: any) => {        
          tracks.forEach((trackType: any) => {
            let myuuid = uuidv4();
            group.tracks.push({ 
              boreholeId: group.boreholeId,
              color: group.color,
              id: myuuid,
              trackType: 5,
              displayOrder: group.tracks.length + 2, 
              new: true, 
              scaleType: 0,
              trackWidth: 100,
              showGridlines: true,
              showValueAxisAnnotation: group.showValueAxisAnnotation,
              lithologyType: trackType.trackTypeId,
              trackTypeId: trackType.trackTypeId,
              displayName: trackType.displayName,
              label: trackType.displayName,
              boreholeName: group.boreholeName,
              projectName: group.projectName,
              curves:[{ boreholeId: group.boreholeId, trackTypeId: trackType.trackTypeId }]
            }); 
          });
        });

        state.notSaved = true;        
      }, 
      addDescriptionTracksToGroups: (state, action: PayloadAction<any>) => {
        const { trackTypeId } = action.payload;
        state.view.groups.forEach((group: any) => {        
            let myuuid = uuidv4();
            group.tracks.push({ 
              boreholeId: group.boreholeId,
              color: group.color,
              id: myuuid,
              trackType: 7,
              textTrackTypeId: trackTypeId,
              displayOrder: group.tracks.length + 2, 
              new: true, 
              scaleType: 0,
              trackWidth: 300,            
              displayName: "Description",
              label: "Description",
              boreholeName: group.boreholeName,
              projectName: group.projectName,
            });           
        });

        state.notSaved = true;        
      }, 
      addGroup: (state, action: PayloadAction<any>) => {
        const { groupName, tracks } = action.payload;
        if (!state.view.groups.find((g: any) => g.groupName == groupName)) {
          const color = getRandomLightColor();
          state.view.groups.push({ groupName, groupId: uuidv4(), tracks, color });
        }
        state.notSaved = true;
        state.showView = true;
      },
      updateGroupOrder: (state, action: PayloadAction<any>) => {  
        const { sourceIndex, destinationIndex } = action.payload;
        if (sourceIndex !== null && destinationIndex !== null) {                 
          const group = state.view.groups.splice(sourceIndex, 1)[0];          
          state.view.groups.splice(destinationIndex, 0, group);
          state.notSaved = true;
        }
      },
      deleteGroup: (state, action: PayloadAction<any>) => {  
        const { groupId } = action.payload;        
        const groupIndex = state.view.groups.findIndex((g: any) => g.groupId == groupId);          
        if (groupIndex > -1) {
          state.view.groups.splice(groupIndex, 1);
          state.notSaved = true;
        }        
      },
      deleteTrack: (state, action: PayloadAction<any>) => {  
        const { groupId, trackId } = action.payload;        
        const group = state.view.groups.find((g: any) => g.groupId == groupId);          
        if (group) {          
          const trackIndex = group.tracks.findIndex((g: any) => g.trackId == trackId);
          if (trackIndex > -1) {
            group.tracks.splice(trackIndex, 1);          
            state.notSaved = true;
          }
        }        
      },
      deleteTracks: (state, action: PayloadAction<any>) => {  
        const { groupId, trackIds } = action.payload;        
        const group = state.view.groups.find((g: any) => g.groupId == groupId);          
        if (group) {    
          trackIds.forEach((trackId: any) => {
            const trackIndex = group.tracks.findIndex((g: any) => g.trackId == trackId);
            if (trackIndex > -1) {
              group.tracks.splice(trackIndex, 1);          
              state.notSaved = true;
            }
          });
        }        
      },
      deleteAllTracks: (state, action: PayloadAction<any>) => {  
        const { groupId } = action.payload;        
        const group = state.view.groups.find((g: any) => g.groupId == groupId);          
        if (group) {    
            group.tracks = [];
            state.notSaved = true;          
        }        
      },
      addTracks: (state, action: PayloadAction<any>) => {          
        const { groupId, tracks } = action.payload;        
        const group = state.view.groups.find((g: any) => g.groupId == groupId);          
        if (group) {          
          if (!group.tracks) {
            group.tracks = [];
          }

          addTracksToGroup2(tracks, group, state.view.groups);
               
          state.notSaved = true;          
        }        
      },      
      updateGroupTrackOrder: (state, action: PayloadAction<any>) => {          
        const { sourceGroupId, destinationGroupId, sourceIndex, destinationIndex, applyToAll } = action.payload;
        if (sourceIndex !== null && destinationIndex !== null) {                 
          if (sourceGroupId === destinationGroupId) {            
            if (applyToAll) {
              state.view.groups.forEach((group: any) => {                
                const tracks = group.tracks;
                const track = tracks.splice(sourceIndex, 1)[0];          
                tracks.splice(destinationIndex, 0, track);
              });
            } else {
              const group = state.view.groups.find((g: any) => g.groupId === sourceGroupId);          
              const tracks = group.tracks;
              const track = tracks.splice(sourceIndex, 1)[0];          
              tracks.splice(destinationIndex, 0, track);
            }
          } else {
            // move between groups
            const sourceGroup = state.view.groups.find((g: any) => g.groupId === sourceGroupId);
            const destinationGroup = state.view.groups.find((g: any) => g.groupId === destinationGroupId);
            const sourceTracks = sourceGroup.tracks;
            const destinationTracks = destinationGroup.tracks;
            const track = sourceTracks.splice(sourceIndex, 1)[0];
            destinationTracks.splice(destinationIndex, 0, track);
          }

          state.notSaved = true;
        
        }
      },
      updateGroupName: (state, action: PayloadAction<any>) => {  
        const { groupId, name } = action.payload;                
        const group = state.view.groups.find((g: any) => g.groupId === groupId);          
        if (group) {
          group.groupName = name;
          state.notSaved = true;
        }        
      },
      updateGroupColor: (state, action: PayloadAction<any>) => {  
        const { groupId, color } = action.payload;                
        const group = state.view.groups.find((g: any) => g.groupId === groupId);   
        const groupBoreholeId = group?.boreholeId;       
        if (group) {
          group.color = color;
          group.tracks.forEach((track: any) => {
            track.color = color;
            track.curves?.forEach((curve: any) => {
              curve.color = color;
            })
          });
          state.notSaved = true;
        }

        state.view.groups.filter((g: any) => g.groupId !== group.groupId).forEach((group: any) => {
          group.tracks.forEach((track: any) => {
            if (track.boreholeId == groupBoreholeId) {
              track.color = color;
            }
            track.curves?.forEach((curve: any) => {
              if (curve.boreholeId == groupBoreholeId) {
                curve.color = color;
              }
            })
          });
        });
      },
      setSelectedViewId: (state, action: PayloadAction<any>) => {  
        const selectedViewId = action.payload; 
        state.selectedViewId = selectedViewId;         
        const selectedTemplate = state.allViews?.find((t :any) => t.id === selectedViewId);        
        state.canSaveView = !selectedTemplate?.cannotOverwrite;
        state.canDeleteView = false;
      },
      setNextViewId:(state, action: PayloadAction<any>) => {
        state.nextViewId = action.payload?.nextViewId;
        state.nextViewLevel = action.payload?.nextViewLevel;
      },
      setSelectedUserViewId: (state, action: PayloadAction<any>) => {  
        const selectedViewId = action.payload; 
        state.selectedViewId = selectedViewId;        
        state.selectedUserViewId = null;        
        state.selectedClientViewId = null;  
        const selectedView = state.allViews?.find((t :any) => t.id === selectedViewId);
        state.canSaveView = !selectedView?.cannotOverwrite;
        state.canDeleteView = false;
      },
      setSelectedClientViewId: (state, action: PayloadAction<any>) => {  
        const selectedViewId = action.payload; 
        state.selectedViewId = selectedViewId;        
        state.selectedUserViewId = null;        
        state.selectedClientViewId = null;  
        const selectedView = state.allViews?.find((t :any) => t.id === selectedViewId);
        state.canSaveView = !selectedView?.cannotOverwrite;
        state.canDeleteView = false;
      },
    },
    extraReducers:  (builder) => {
      builder.addCase(getViews.pending, (state, { payload }) => {        
          //state.viewsLoading = true;
          state.viewsLoaded = false;
          state.error = null;
        });
      builder.addCase(getViews.fulfilled, (state, action) => {          
          const { payload } = action;
          state.viewsLoading = false;
          if (!payload.success) {
            state.error = payload.errors;
          } else {              
            state.allViews = payload.allViews;            
            state.userViews = payload.userViews;            
            state.clientViews = payload.clientViews;
            state.viewsLoaded = true;
            sortViews(state);
          }
        });
      builder.addCase(getViews.rejected, (state, action) => {                 
          state.viewsLoading = false;
          state.error = action.error;
        });

      builder.addCase(getView.pending, (state, { payload }) => {
          state.viewLoading = true;
        });

      builder.addCase(getView.rejected, (state, { payload }) => {
          state.viewLoading = false;
        });

      builder.addCase(getView.fulfilled, (state, { payload }:any) => {
        state.viewLoading = false;
        debugger;
        if (!payload.success) {
          state.error = payload.errors;
        } else {
          
          const view: any = payload;
          view.tracks.forEach((track: any) => {
            track.collapsed = true;
          });
          
          state.view = view;
        }
        });

      builder.addCase(addView.fulfilled, (state, { payload, meta }: any) => {
        //add to template list                      
        state.allViews.push({ id: payload.templateId, name: payload.name, cannotOverwrite: false, containsData: true, templateLevel: meta.arg.templateLevel });
        switch (meta.arg.templateLevel) {          
        case 1: 
          state.userViews.push({ id: payload.templateId, name: payload.name, cannotOverwrite: false, containsData: true });
          break;          
        case 4: 
          state.clientViews.push({ id: payload.templateId, name: payload.name, cannotOverwrite: false, containsData: true });
          break;
        }
        
        if (!state.view) {
          state.view = {};
        }
        
        state.view.name = payload.name;            
        state.canSaveView = true;            
        sortViews(state);
        if (state.nextViewId) 
        {   
            //state.urlViewId = state.nextTemplateId;
            state.nextViewId = null;
        } else {
          //state.urlTemplateId = payload.templateId;              
        }            
        state.notSaved = false;
        toast.success("Template saved");
      });

      builder.addCase(addView.rejected, (state, { payload }) => {          
        toast.error("View failed to save");
      });
    }
})

export const { clearView,
               addBorehole, 
               addBoreholes,
               addGroup, 
               updateGroupOrder, 
               deleteGroup, 
               addTracks, 
               deleteTrack, 
               deleteTracks, 
               deleteAllTracks,
               updateGroupTrackOrder, 
               updateGroupName,
               updateGroupColor,
               addTracksToGroups,
               addGraphTracksToGroups,
               addImageTracksToGroups,
               addCumulativeTracksToGroups,
               addTadpoleTracksToGroups,
               addLithologyTracksToGroups,
               addDescriptionTracksToGroups,
               setSelectedUserViewId,
               setSelectedClientViewId,
               setSelectedViewId,
               setNextViewId
              } = viewsSlice.actions

export default viewsSlice.reducer;