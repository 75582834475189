import Select from 'react-select';

const Views = (props: any) => {

    const {                
        userViewOptions,        
        clientViewOptions,        
        onChangeUserView,
        onChangeClientView,
        selectedViewId      
      } = props; 


    const customStyles = {
        option: (provided: any, { data, isDisabled, isFocused, isSelected }:any) => ({
          ...provided,          
          backgroundColor: isSelected ? 'rgba(0, 54, 113, 1)' : '#FFFFFF',
          color: isDisabled
            ? '#ccc'
            : isSelected ? "white" : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          padding: 5,
          paddingLeft: "12px",
          "&:hover": {
            backgroundColor: "rgba(0, 54, 113, 0.3)"
          }
        })        
      };

    let  selectedUserViewIdValue = null,    
    selectedClientViewIdValue = null;

    const getSelectedViewId = () => {
      let selectedViewIdValue: any = null;
      if (selectedViewId) {                

        if (selectedViewIdValue) {
          selectedClientViewIdValue = selectedViewIdValue;
        }
    }};

    getSelectedViewId();

return(
<div className="field">
          <label className="label">Available Views</label>
         
          <div className='mt-2'>
            User
            <div className="control">
                <Select options={userViewOptions} styles={customStyles} onChange={onChangeUserView} value={selectedUserViewIdValue} classNamePrefix="select"></Select>                         
            </div>
          </div>
          <div className='mt-2'>
            Client
            <div className="control">
                <Select options={clientViewOptions} styles={customStyles} onChange={onChangeClientView} value={selectedClientViewIdValue} classNamePrefix="select"></Select>                         
            </div>
          </div>
      </div>
)
}

export default Views;